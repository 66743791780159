/* eslint-disable react/no-danger */
import React, { FC, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { ContentPageLayout } from '@components/Layout';
import { Form } from '@components/Form';
import { observer } from 'mobx-react';
import { TwoColorButton, Spinner } from '@primitives';
import styled from 'styled-components';
import { Hr, Button, Box } from '@components/Primitives';
import { NaviButtons } from '@components//NaviButtons';
import { Modal } from '@components//Modal';
import { TextSection } from '@components/TextSection';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { until } from '../../styles';
import { RESIDENTS, PAGE_NAV_ITEMS } from '@constants';
import { useStores } from '@stores';

interface MyInformationResidentsPageProps {
  data: IMarkdownData;
}

const MixedFormContainer = styled.section`
  position: relative;
  .input-wrapper {
    margin-bottom: 1rem;
  }
  .form-footer {
      margin-top: 1rem;
  }
  ${until('md', `
    .input-wrapper:not(.read-only) {
      margin-bottom: 2rem;
    }< o
    button {
      min-width: calc(50% - 0.5rem);
      &.remove-resident {
        > span {
          margin-right: 1rem;
        }
      }
    }
  `)}
`;

const MyInformationResidentsPage: FC<MyInformationResidentsPageProps> = observer(({
  data: { markdownRemark: { frontmatter: { blocks: texts } } },
}) => {
  const {
    agreementStore: {
      selectedAgreementId, getAgreementParties, getResidents,
      addResident, state, updateResident,
      removeResident, currentOtherAgreementParty, currentResidents, currentAgreementParties
    },
  }: {
    agreementStore: IAgreementStore;
  } = useStores();

  useEffect(() => {
    if (selectedAgreementId && !currentAgreementParties) getAgreementParties(selectedAgreementId);
    if (selectedAgreementId && !currentResidents) getResidents(selectedAgreementId);
  }, [selectedAgreementId, getResidents, getAgreementParties]);

  const [modalOpen, setModalOpen] = useState(false);
  const [addResidentFormVisible, setAddResidentFormVisible] = useState(false);
  const [newResidentValues, setNewResidentValues] = useState({});
  const [selectedResident, setSelectedResident] = useState<number | undefined>();
  const [deletedResident, setDeletedResident] = useState<number | null>(null);

  const isLoading = state === 'Loading';

  const onNewResidentSubmit = async (data: IResident, callback: Function) => {
    const result = await addResident(data, callback);
    setAddResidentFormVisible(false);
    return result;
  };

  const onUpdateSubmit = async (_changed: IResident, callback: Function, formValues: IResident) => {
    const picked = (({ first_name, last_name }) => ({ first_name, last_name }))(formValues);
    await updateResident(formValues.party_id, picked, callback);
  };

  const onRemoveConfirmClick = async () => {
    setDeletedResident(selectedResident!);
    setModalOpen(false);

    try {
      await removeResident(selectedResident!);
      setDeletedResident(null)
    } catch {
      setDeletedResident(null)
    }
    setSelectedResident(undefined);
  };

  const onRemoveCancelClick = () => {
    setModalOpen(false);
    setSelectedResident(undefined);
  };

  const onResidentRemoveClick = (partyId: number) => {
    setModalOpen(true);
    setSelectedResident(partyId);
  };

  const onResidentAddClick = () => {
    setAddResidentFormVisible(true);
  };

  const onCancelResidentAddClick = () => {
    setAddResidentFormVisible(false);
    setNewResidentValues({});
  };

  return (
    <ContentPageLayout wideContent>

      <TextSection
        title={<M id='fields.label.otherResidents.title'/>}
        text={<M id='fields.label.otherResidents.text'/>}
        afterTitle={<NaviButtons items={PAGE_NAV_ITEMS.residents} />}
      />

      {isLoading && <Spinner color="mainBlue" />}

      {currentOtherAgreementParty && (
        <>
          <TextSection level="3" title={<M id='fields.label.otherContractResidents.title'/>} text={<M id='fields.label.otherContractResidents.text'/>} />
          <Box mt="2">
            <Form
              id="other-contract-resident"
              values={currentOtherAgreementParty}
              fields={RESIDENTS.otherAgreementParty}
              styles={['readOnly']}
              loading={isLoading}
            />
            <Hr />
          </Box>
        </>
      )}

      <TextSection level="3" title={<M id='fields.label.others.title'/>} />

      {currentResidents?.map((resident) => {
        return (
          <MixedFormContainer key={resident.party_id}>
            <Form
              id={`resident-${resident.party_id}`}
              values={resident}
              disableFooter={!!resident.end_date}
              fields={resident.end_date ? RESIDENTS.otherResidentsReadonly : RESIDENTS.otherResidents}
              footer={(
                !resident.end_date && (
                  <TwoColorButton
                    id="remove-resident"
                    ml="1"
                    symbol="-"
                    loading={deletedResident === resident.party_id}
                    title="pages.myInformation.removeResident"
                    onClick={() => onResidentRemoveClick(resident.party_id)}
                  />
                )
              )}
              onSubmit={onUpdateSubmit}
            />

            <Hr />
          </MixedFormContainer>
        );
      })}

      {!addResidentFormVisible && <TwoColorButton id="add-resident" symbol="+" title="pages.myInformation.addResident" onClick={onResidentAddClick} />}
      {addResidentFormVisible && (
        <MixedFormContainer>
          <Form
            id="add-new-resident"
            fields={RESIDENTS.newResident}
            values={newResidentValues}
            onSubmit={onNewResidentSubmit}
            footer={(
              <Button ml="1" id="cancel-resident-add" color="white" background="textBlue" onClick={onCancelResidentAddClick}>
                <M id="fields.label.cancel" />
              </Button>
            )}
          />
        </MixedFormContainer>
      )}
      <Hr />

      {modalOpen && (
        <Modal
          title="Poista asukas"
          primaryButtonText={<M id="pages.myInformation.removeResident" />}
          secondaryButtonText={<M id="fields.label.cancel" />}
          onPrimaryClick={onRemoveConfirmClick}
          onSecondaryClick={onRemoveCancelClick}
          maxWidth="400px"
        >
          <M id="pages.myInformation.removeResidentModalBody" />
        </Modal>
      )}
    </ContentPageLayout>
  );
});

export const pageQuery = graphql`
query MyInformationResidentsQuery {
  markdownRemark(frontmatter: {path: {eq: "/me/residents/"}}) {
    frontmatter {
      title
      path
      blocks {
        text
        title
      }
    }
  }
}
`;

export default MyInformationResidentsPage;
